const URL_GET_KODE_OTP_AUTH = '/account/otp-auth';
// const GET_TOKEN =
//    'eyJhbGciOiJIUzI1NiJ9.eyJleHAiOjIzMDg3OTU2NDcsImlzcyI6InN0YWdpbmctYXBpLndpcy1wYXkuY29tIiwiYXVkIjpbIllvdW5nIiwiT2xkIl0sIm91dGxldF9pZCI6NDUyfQ.d8dN80IEg0UIiaMqNiIBZAQ9m9TCnmYlr5b6dsZBykI';
// const GET_PIN = '010101';
// const GET_SIGNATURE =
//    'b9c48be8-a740-4747-a9e1-7a23e747bc23-2023-03-01 11:12:19.000000';
// Production
const GET_TOKEN =
   'eyJhbGciOiJIUzI1NiJ9.eyJleHAiOjMyNTc0MTg5MjUsImlzcyI6ImFwaS53aXMtcGF5LmNvbSIsImF1ZCI6WyJZb3VuZyIsIk9sZCJdLCJvdXRsZXRfaWQiOjE2NDkxfQ.IuOZVL8wwcUA7dzzwyY5fuGAovg4uQju02Or-w7dE6Q';
const GET_SIGNATURE =
   '4c2260f2-c45c-4dc5-8493-492a669348c7-2023-03-22 00:00:00.000000';
const GET_PIN = '101010';
const URL_GET_PRODUCTS = '/home?section=team_buying';
const URL_GET_PRODUCTS_CATEGORIES =
   '/home?section=product_categories&_limit=30';
const URL_GET_PRODUCTS_BRANDS =
   '/product-categories/%(id)s/product-brands?%(params)s';
const URL_GET_PRODUCTS_SUBCATEGORIES =
   '/product-brands/%(id)s/product-subcategories?%(params)s';
const URL_GET_SUBCATEGORIES_PRODUCTS =
   '/products?product_subcategory_id=%(id)s%(params)s';
const URL_GET_TOKEN_PRODUCT = '/transaction/ayoconnect/%(product)s/order';
const URL_GET_PAYMENT_CODE = '/transaction/ayoconnect/%(product)s/confirm';
const URL_GET_TRANSACTION_DETAIL = '/transactions/%(id)s';

export {
   URL_GET_KODE_OTP_AUTH,
   URL_GET_PRODUCTS,
   URL_GET_PRODUCTS_CATEGORIES,
   URL_GET_PRODUCTS_BRANDS,
   URL_GET_PRODUCTS_SUBCATEGORIES,
   URL_GET_SUBCATEGORIES_PRODUCTS,
   GET_TOKEN,
   GET_SIGNATURE,
   GET_PIN,
   URL_GET_TOKEN_PRODUCT,
   URL_GET_PAYMENT_CODE,
   URL_GET_TRANSACTION_DETAIL,
};
