import React from 'react';
import Lottie from 'lottie-react';
import animationData from 'resources/lottie/splashscreen.json';

const SplashScreen = () => {
   return (
      <div className="splash-screen">
         <Lottie
            className="splash-icon"
            animationData={animationData}
            autoplay={true}
            loop={true}
         />
      </div>
   );
};

export default SplashScreen;
