/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Collapse, Row, Typography, message } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import Layout from 'components/layout/Layout';
import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import copy from 'resources/img/icons/copy.png';
import statusBayar from 'resources/img/statusbayar.png';
import toko from 'resources/img/icons/toko.png';
import { formatPrice } from 'helpers/formatPrice';
import Title from 'antd/lib/typography/Title';

const { Text } = Typography;
const { Panel } = Collapse;

const StatusBayar = () => {
   const [messageApi, contextHolder] = message.useMessage();

   const hendleCopyClipboard = (text) => {
      navigator.clipboard.writeText(text);
      messageApi.open({
         type: 'success',
         content: 'Copy to clipboard',
      });
   };
   const ref = createRef(null);

   return (
      <Layout header="product" headerTitle="Status Pembayaran">
         {contextHolder}

         <Row className="container-index Top-UP">
            <Row>
               <Col span={24} className="col-center" style={{ margin: 15 }}>
                  <img src={statusBayar} alt="status bayar" width={150} />
               </Col>
            </Row>
            <Row className="detail-transaksi" ref={ref}>
               <Col span={24}>
                  <Title level={4}>Detail Transaksi</Title>
               </Col>
               <Col span={24}>
                  <Text className="detTrans-text">Kode Transaksi</Text>
               </Col>
               <Col span={24}>
                  <Text className="detTrans-text">
                     <strong>TRX-787-2023-03-000003494</strong>
                     <img
                        src={copy}
                        alt="payment outlet"
                        width={16}
                        height={16}
                        style={{ marginLeft: 10, cursor: 'pointer' }}
                        onClick={() => hendleCopyClipboard()}
                     />
                  </Text>
               </Col>{' '}
               <Col span={24}>
                  <Text className="detTrans-text">Status Pembayaran</Text>
                  <Text className="pedding">Menunggu</Text>
               </Col>
               <Col span={24}>
                  <Text className="detTrans-text-metode">
                     Metode Pembayaran
                  </Text>
               </Col>
               <Col span={24} className="col-left">
                  <img
                     src={toko}
                     alt="payment outlet"
                     width={16}
                     style={{ marginRight: 20 }}
                  />{' '}
                  <Text className="detTrans-text-cash">Cash di Outlet</Text>
               </Col>
               <Col span={24}>
                  <Text className="detTrans-text-metode">Total Pembayaran</Text>
               </Col>
               <Col span={24} className="col-left">
                  <Text className="detTrans-text-cash">
                     {formatPrice(125000)}
                  </Text>
               </Col>{' '}
               <Col span={24}>
                  <Collapse
                     size="small"
                     expandIconPosition="end"
                     className="rincian-bayar"
                     expandIcon={({ isActive }) => (
                        <RightOutlined rotate={isActive ? 270 : 90} />
                     )}
                  >
                     <Panel
                        header="RincianPembayaran"
                        key="1"
                        style={{ marginBottom: 10 }}
                     >
                        <Row>
                           <Col span={12}>
                              <Text className="detTrans-text">Tagihan</Text>
                           </Col>
                           <Col span={12} className="col-right">
                              <Text className="detTrans-text-bold">
                                 {formatPrice(122500)}
                              </Text>
                           </Col>{' '}
                           <Col span={12}>
                              <Text className="detTrans-text">Biaya Admin</Text>
                           </Col>
                           <Col span={12} className="col-right">
                              <Text className="detTrans-text-bold">
                                 {formatPrice(2500)}
                              </Text>
                           </Col>{' '}
                           <Col span={12} className="col-left">
                              <Text className="detTrans-text-bold">
                                 Total Pembayaran
                              </Text>
                           </Col>{' '}
                           <Col span={12} className="col-right">
                              <Text className="detTrans-text-bold">
                                 {formatPrice(125000)}
                              </Text>
                           </Col>
                        </Row>
                     </Panel>
                  </Collapse>
               </Col>
            </Row>

            <Row className="lihat-rincian">
               <Col span={24} className="col-center">
                  <Button className="button-primary">
                     Lihat Rincian Transaksi
                  </Button>
               </Col>

               <Col span={24} className="col-center">
                  <Text className="bantuan">
                     Butuh Bantuan? <Link to="#">Klik Disini</Link>
                  </Text>
               </Col>
            </Row>
         </Row>
      </Layout>
   );
};

export default StatusBayar;
