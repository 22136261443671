import { Col, Form, Row } from 'antd';
import Input from 'antd/lib/input/Input';
import React from 'react';

const FormVoucher = (props) => {
   const { code, onchangeID, onchangeZone } = props;
   return (
      <>
         <Form name="basic">
            <Form.Item hasFeedback>
               {code === 'Mobile Legends' ? (
                  <Row className="ML">
                     <Col span={11}>
                        <Input
                           placeholder="User ID"
                           id="validating"
                           className="input-topUP ML"
                           onChange={onchangeID}
                           allowClear
                        />
                     </Col>
                     <Col span={11}>
                        <Input
                           placeholder="Zone ID"
                           id="validating"
                           className="input-topUP ML"
                           onChange={onchangeZone}
                           allowClear
                        />
                     </Col>
                  </Row>
               ) : (
                  <Input
                     placeholder="User ID"
                     id="validating"
                     className="input-topUP"
                     onChange={onchangeID}
                     allowClear
                  />
               )}
            </Form.Item>{' '}
         </Form>
      </>
   );
};

export default FormVoucher;
