import axios from 'axios';

const restApi = axios.create({
   baseURL: process.env.REACT_APP_BASE_URL,
   timeout: process.env.REACT_APP_TIMEOUT || 10000,
   headers: {
      'Content-Type': 'application/json',
   },
});

restApi.interceptors.request.use(
   async (config) => {
      if (config.method === 'get' || config.data === undefined) {
         config.data = true;
      }

      return config;
   },
   (error) => Promise.reject(error),
);

restApi.interceptors.response.use(
   function (response) {
      return response;
   },
   function (error) {
      return Promise.reject(error);
   },
);

export default restApi;
