import { Col, Row, Typography } from 'antd';

const { Title } = Typography;

const VoucherNotification = (code) => {
   switch (code) {
      case 'Mobile Legends':
         return (
            <Row className="row-notification" style={{ marginBottom: 15 }}>
               <Col span={24}>
                  <Title level={5}>Keterangan</Title>
                  <div className="notif">
                     <ul className="notif-UL" style={{ marginBottom: 0 }}>
                        <li>
                           UserID pelanggan di Game:
                           <br />
                           <strong>UserID=2580-28985.</strong>
                        </li>
                        <li>
                           Masukkan Kode Token ke dalam akun Mobile Legends
                           Diamonds, yang didapat setelah transaksi berhasil.
                        </li>
                     </ul>
                  </div>
               </Col>
            </Row>
         );
      case 'Free Fire':
         return (
            <Row className="row-notification" style={{ marginBottom: 15 }}>
               <Col span={24}>
                  <Title level={5}>Keterangan</Title>
                  <div className="notif">
                     <ul className="notif-UL" style={{ marginBottom: 0 }}>
                        <li>
                           UserID pelanggan di Game:
                           <br />
                           <strong>UserID=258028988.</strong>
                        </li>
                        <li>
                           Masukkan kode token ke dalam akun game Free Fire,
                           yang didapat setelah transaksi berhasil.
                        </li>
                     </ul>
                  </div>
               </Col>
            </Row>
         );

      default:
         break;
   }
};
export default VoucherNotification;
