const MenungguBayarTitle = (data) => {
   switch (data?.product?.product_subcategory?.name) {
      case 'PDAM':
         return data?.product_name;
      case 'Pra Bayar':
         return 'PLN Prepaid';
      case 'Pasca Bayar':
         return 'PLN Postpaid';
      case 'BPJS':
         return 'BPJS Kesehatan';
      case 'PBB':
         return data?.product?.name;
      default:
         return data?.product?.product_brand?.name;
   }
};
export default MenungguBayarTitle;
