import restAPI from 'hooks/Interceptor';
import {
   URL_GET_PRODUCTS_BRANDS,
   URL_GET_PRODUCTS_SUBCATEGORIES,
   URL_GET_SUBCATEGORIES_PRODUCTS,
   URL_GET_TOKEN_PRODUCT,
   URL_GET_PAYMENT_CODE,
   GET_PIN,
   GET_SIGNATURE,
   GET_TOKEN,
   URL_GET_TRANSACTION_DETAIL,
} from 'constants/Auth';
import { sprintf } from 'sprintf-js';
import { ec } from './Crypto';
import handleError, { showErrorMessage } from './HandleError';
import { message } from 'antd';

export const getProductBrand = (id, params) => {
   let promiseSubCategories = new Promise((resolve, reject) => {
      restAPI
         .get(sprintf(URL_GET_PRODUCTS_BRANDS, { id, params: params || '' }))
         .then((response) => {
            const { data } = response.data;
            resolve(data);
         });
   });
   return promiseSubCategories;
};
export const getProductSubCatagories = (id, params) => {
   let promiseSubCategories = new Promise((resolve, reject) => {
      restAPI
         .get(
            sprintf(URL_GET_PRODUCTS_SUBCATEGORIES, {
               id,
               params: params || '',
            }),
         )
         .then((response) => {
            const { data } = response.data;
            resolve(data);
         })
         .catch((error) => {
            message.error(showErrorMessage(handleError(error)));
            reject();
         });
   });
   return promiseSubCategories;
};
export const getProductSubCatProduct = (id, params) => {
   let promiseSubCategories = new Promise((resolve, reject) => {
      restAPI
         .get(
            sprintf(URL_GET_SUBCATEGORIES_PRODUCTS, {
               id,
               params: params || '',
            }),
         )
         .then((response) => {
            const { data } = response.data;
            resolve(data);
         });
   });
   return promiseSubCategories;
};

export const getTokenProduct = (product, body) => {
   let promiseGetToken = new Promise((resolve, reject) => {
      restAPI
         .post(
            sprintf(URL_GET_TOKEN_PRODUCT, {
               product,
            }),
            body,
            {
               headers: { Signature: GET_SIGNATURE, 'X-AUTH-TOKEN': GET_TOKEN },
            },
         )
         .then((response) => {
            const { data } = response.data;
            resolve(data);
         })
         .catch((error) => {
            message.error(showErrorMessage(handleError(error)));
            reject();
         });
   });
   return promiseGetToken;
};
export const getDataCheckout = (product, token, email) => {
   let promiseGetCheckout = new Promise((resolve, reject) => {
      restAPI
         .post(
            sprintf(URL_GET_PAYMENT_CODE, {
               product,
            }),
            {
               pin: ec(GET_PIN), // Get `product_id` references from product hierarchy
               token: token, // Should be encryption
               payment: 'PAYMENT_CODE',
               email: email,
            },
            {
               headers: { Signature: GET_SIGNATURE, 'X-AUTH-TOKEN': GET_TOKEN },
            },
         )
         .then((response) => {
            const { data } = response.data;
            resolve(data);
         })
         .catch((error) => {
            message.error(showErrorMessage(handleError(error)));
            reject();
         });
   });
   return promiseGetCheckout;
};
export const getDetailTransaction = (id) => {
   let promiseGetCheckout = new Promise((resolve, reject) => {
      restAPI
         .get(
            sprintf(URL_GET_TRANSACTION_DETAIL, {
               id,
            }),
            {
               headers: { Signature: GET_SIGNATURE, 'X-AUTH-TOKEN': GET_TOKEN },
            },
         )
         .then((response) => {
            const { data } = response.data;
            resolve(data);
         })
         .catch((error) => {
            message.error(showErrorMessage(handleError(error)));
            reject(true);
         });
   });
   return promiseGetCheckout;
};
