import { lazy } from 'react';

const Home = lazy(
   () =>
      new Promise((resolve) =>
         setTimeout(() => resolve(import('containers/home')), 1200),
      ),
);
const Telepon = lazy(() => import('containers/Lainnya/Telepon'));
const Indihome = lazy(() => import('containers/Lainnya/Indihome'));
const Pulsa = lazy(() => import('containers/TopUP/Pulsa'));
const DompetEL = lazy(() => import('containers/TopUP/DompetEL'));
const PLN = lazy(() => import('containers/TopUP/PLN'));
const VoucherGame = lazy(() => import('containers/TopUP/VoucherGame'));
const PDAM = lazy(() => import('containers/TopUP/PDAM'));
const Bpjs = lazy(() => import('containers/TopUP/Bpjs'));
const ComingSoon = lazy(() => import('containers/TopUP/ComingSoon'));
const Modem = lazy(() => import('containers/Lainnya/Modem'));
const SellOut = lazy(() => import('containers/Lainnya/SellOut'));
const Telkom = lazy(() => import('containers/Lainnya/Telkom'));
const PBB = lazy(() => import('containers/Lainnya/PBB'));
const Gas = lazy(() => import('containers/Lainnya/Gas'));
const Sportify = lazy(() => import('containers/Lainnya/Sportify'));
const StatusBayar = lazy(() => import('components/StatusBayar'));
const RincianBayar = lazy(() => import('components/RincianBayar'));
// const KartuPerdana = lazy(() => import('containers/Lainnya/KartuPerdana'));
// const UangEL = lazy(() => import('containers/TopUP/UangEL'));
// const Wallet = lazy(() => import('containers/TopUP/KirimTunai'));
// const Streaming = lazy(() => import('containers/Lainnya/Streaming'));
// const TagihanKredit = lazy(() => import('containers/Lainnya/TagihanKredit'));
// const Asuransi = lazy(() => import('containers/TopUP/Asuransi'));
// const Pascabayar = lazy(() => import('containers/Lainnya/Pascabayar'));
// const VoucherFisik = lazy(() => import('containers/Lainnya/VoucherFisik'));

// eslint-disable-next-line import/no-anonymous-default-export
export default [
   {
      path: '/',
      exact: true,
      component: Home,
   },
   {
      path: '/pulsa-paket-data',
      exact: true,
      component: Pulsa,
   },
   {
      exact: true,
      component: PLN,
      path: '/pln',
   },
   {
      exact: true,
      component: PDAM,
      path: '/pdam',
   },
   {
      exact: true,
      component: Bpjs,
      path: '/bpjs',
   },
   {
      exact: true,
      component: DompetEL,
      path: '/dompet-elektronik',
   },
   {
      exact: true,
      component: VoucherGame,
      path: '/voucher-game',
   },
   {
      exact: true,
      // component: UangEL,
      component: ComingSoon,
      path: '/coming-soon/uang-elektronik',
   },
   {
      exact: true,
      // component: Pascabayar,
      component: ComingSoon,
      path: '/coming-soon/pascabayar',
   },

   {
      exact: true,
      // component: VoucherFisik,
      component: ComingSoon,
      path: '/coming-soon/voucher-fisik',
   },

   {
      exact: true,
      // component: KartuPerdana,
      component: ComingSoon,
      path: '/coming-soon/kartu-perdana',
   },
   {
      exact: true,
      component: Modem,
      path: '/Modem',
   },
   {
      exact: true,
      component: ComingSoon,
      path: '/coming-soon',
   },
   {
      exact: true,
      component: SellOut,
      path: '/SellOut',
   },
   {
      exact: true,
      component: Telkom,
      path: '/Telkom',
   },
   {
      exact: true,
      // component: TagihanKredit,
      component: ComingSoon,
      path: '/coming-soon/multi-finance',
   },
   {
      exact: true,
      component: PBB,
      path: '/PBB',
   },
   {
      exact: true,
      component: Gas,
      path: '/GAS',
   },
   {
      exact: true,
      // component: Streaming,
      component: ComingSoon,
      path: '/coming-soon/streaming',
   },
   {
      exact: true,
      // component: Streaming,
      component: ComingSoon,
      path: '/coming-soon/pengiriman-barang',
   },
   {
      exact: true,
      component: Sportify,
      path: '/voucher-game/sportify-premium',
   },

   {
      exact: true,
      // component: Asuransi,
      component: ComingSoon,
      path: '/coming-soon/asuransi',
   },

   {
      exact: true,
      component: VoucherGame,
      path: '/voucher-game',
   },
   {
      exact: true,
      // component: Wallet,
      component: ComingSoon,
      path: '/coming-soon/transfer-wallet',
   },

   {
      exact: true,
      component: ComingSoon,
      path: '/coming-soon',
   },

   {
      path: '/tagihan-telepon',
      exact: false,
      component: Telepon,
   },
   {
      path: '/tagihan-indihome',
      exact: false,
      component: Indihome,
   },
   {
      path: '/status-pembayaran',
      exact: false,
      component: StatusBayar,
   },
   {
      path: '/rincian-traksaksi',
      exact: false,
      component: RincianBayar,
   },
];
