import { Suspense } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import SplashScreen from 'components/SplashScreen';
import DompetEL from 'components/dompet-elektronik/DompetEL';
import { routes, privateRoutes } from 'Routes';

import 'resources/style/style.scss';

import PaketData from 'components/paketData/paketData';
import MenungguBayar from 'components/MenungguBayar';
import StatusBayar from 'components/StatusBayar';
import Voucher from 'components/Voucher/Voucher';
import RincianBayar from 'components/RincianBayar';

function App() {
   return (
      <Suspense fallback={<SplashScreen />}>
         <Routes>
            {routes.map((route, index) => (
               <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  element={<route.component />}
               />
            ))}

            <Route element={<Outlet />}>
               {privateRoutes.map((route, index) => (
                  <Route
                     key={index}
                     path={route.path}
                     exact={route.exact}
                     element={<route.component />}
                  />
               ))}
            </Route>
            <Route path="dompet-elektronik/:slug" element={<DompetEL />} />
            <Route path="voucher-game/:slug" element={<Voucher />} />
            <Route path="pulsa-paket-data/:slug" element={<PaketData />} />
            <Route path="/menunggu-bayar/:id" element={<MenungguBayar />} />
            <Route path="/status-pembayaran/:id" element={<StatusBayar />} />
            <Route path="/rincian-traksaksi/:id" element={<RincianBayar />} />
         </Routes>
      </Suspense>
   );
}

export default App;
