/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row, Typography, Divider } from 'antd';
import Layout from 'components/layout/Layout';
import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import print from 'resources/img/icons/print.png';
import telkomsel from 'resources/img/icons/gsm/logo-telkomsel.png';
import sent from 'resources/img/icons/sent.png';
import { formatPrice } from 'helpers/formatPrice';
import * as htmlToImage from 'html-to-image';
import Title from 'antd/lib/typography/Title';
import info from 'resources/img/misc/info2.png';
const createFileName = (extension = '', ...names) => {
   if (!extension) {
      return '';
   }

   return `${names.join('')}.${extension}`;
};

const { Text } = Typography;

const RincianBayar = () => {
   const ref = createRef(null);
   const takeScreenShot = async (node) => {
      const dataURI = await htmlToImage.toJpeg(node);
      return dataURI;
   };
   const download = (
      image,
      { name = 'screenshot', extension = 'jpg' } = {},
   ) => {
      const a = document.createElement('a');
      a.href = image;
      a.download = createFileName(extension, name);
      a.click();
   };
   const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

   const handleShare = async () => {
      const newFile = await htmlToImage.toBlob(ref.current);
      const data = {
         files: [
            new File([newFile], 'image.png', {
               type: newFile.type,
            }),
         ],
         title: 'Image',
         text: 'image',
      };

      try {
         if (!navigator.canShare(data)) {
            console.error("Can't share");
         }
         await navigator.share(data);
      } catch (err) {
         console.error(err);
      }
   };
   return (
      <Layout header="product" headerTitle="Rincian Transaksi">
         <Row className="container-index Top-UP">
            <Row className="rincian-transaksi" ref={ref}>
               <Col span={24} className="notifcol">
                  <div className="notif violet">
                     <img src={info} alt="payment time" className="info-misc" />
                     <div className="notif-UL">
                        Struk ini adalah bukti pembayaran yang sah.
                     </div>
                  </div>
               </Col>
               <Col span={24} className="col-left" style={{ marginTop: 15 }}>
                  <img
                     src={telkomsel}
                     alt="payment outlet"
                     width={20}
                     className="icon-product"
                  />
                  <Title level={4}>Telkomsel Prabayar</Title>
               </Col>
               <Col span={12}>
                  <Text className="detTrans-text">Status</Text>
               </Col>
               <Col span={12} className="col-right">
                  <Text className="success">Berhasil</Text>
               </Col>
               <Col span={12}>
                  <Text className="detTrans-text">Nomor Transaksi</Text>
               </Col>
               <Col span={12} className="col-right">
                  <Text className="detTrans-text-bold">
                     TRX-787-2023-03-000003494
                  </Text>
               </Col>
               <Col span={12}>
                  <Text className="detTrans-text">Tanggal</Text>
               </Col>
               <Col span={12} className="col-right">
                  <Text className="detTrans-text-bold">
                     26 Nov 2022 19:30:38
                  </Text>
               </Col>{' '}
               <Col span={12}>
                  <Text className="detTrans-text">Nomor Hp</Text>
               </Col>
               <Col span={12} className="col-right">
                  <Text className="detTrans-text-bold">081234567890</Text>
               </Col>
               <Divider />
               <Col span={12}>
                  <Text className="detTrans-text-total">Total Tagihan</Text>
               </Col>
               <Col span={12} className="col-right">
                  <Text className="detTrans-text-total">
                     {formatPrice(125000)}
                  </Text>
               </Col>{' '}
            </Row>

            <Row className="Kirim-bukti">
               <Col span={11} className="col-center">
                  <Button
                     className="button-primary"
                     onClick={downloadScreenshot()}
                  >
                     <img
                        src={print}
                        alt="payment outlet"
                        width={16}
                        style={{ marginRight: 10 }}
                     />
                     Cetak Bukti
                  </Button>
               </Col>
               <Col span={11} className="col-center">
                  <Button className="button-primary" onClick={handleShare}>
                     <img
                        src={sent}
                        alt="payment outlet"
                        width={16}
                        style={{ marginRight: 10 }}
                     />
                     Kirim Bukti
                  </Button>
               </Col>
               <Col span={24} className="col-center">
                  <Text className="bantuan">
                     Butuh Bantuan? <Link to="#">Klik Disini</Link>
                  </Text>
               </Col>
            </Row>
         </Row>
      </Layout>
   );
};

export default RincianBayar;
