function formatNumber(num, prefix) {
   const value = parseInt(num) || '';
   if (!value) {
      return '0';
   }
   return prefix && num
      ? value?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      : value?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

function removeDot(num, prefix) {
   const removeSeparator = prefix ? num.split(prefix).join('') : num;
   return removeSeparator.split('.').join('');
}

function phoneNumber(value) {
   const number = value.replace(/^0+/, '');
   var zero = value.substring(0, 1);
   var twonumber = number.substring(0, 2);
   var numberIndo;
   if (zero === '0') {
      numberIndo = '+62' + number;
   } else if (twonumber === '62') {
      numberIndo = '+' + number;
   } else {
      numberIndo = '+62' + number;
   }
   return numberIndo;
}

function remove62(value) {
   const number = value?.substring(0, 1) ? value : value?.replace(/^0+/, '');
   var twonumber = value?.substring(0, 3);
   var numberIndo;
   if (twonumber === '+62') {
      numberIndo = `0${value.substring(3, value.length)}`;
   } else {
      numberIndo = number;
   }
   return numberIndo;
}

export { formatNumber, removeDot, phoneNumber, remove62 };

export const formatPrice = (data) => {
   const temp = Number(data)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&.')
      .split('.');
   return `Rp ${temp.slice(0, temp.length - 1).join('.')}`;
};
