import React from 'react';

const HeaderMenu = (props) => {
   const { image, title, active } = props;

   return (
      <div className="header-menu">
         <img src={image} alt="icon header" className="header-icon" />
         <span className={`header-text ${active ? 'active' : ''}`}>
            {title}
         </span>
      </div>
   );
};

export default HeaderMenu;
