import { Col, Layout as TheLayout, Row } from 'antd';
import Header from './Header';

const Layout = ({ children, header, headerTitle }) => {
   return (
      <TheLayout>
         <Header type={header} title={headerTitle} />
         <TheLayout.Content>
            <Col span={24} style={{ maxWidth: 500 }}>
               <Row style={{ height: '100%', flexDirection: 'column' }}>
                  {children}
               </Row>
            </Col>
         </TheLayout.Content>
      </TheLayout>
   );
};

export default Layout;
