/* eslint-disable react-hooks/exhaustive-deps */
import {
   Button,
   Col,
   Collapse,
   Divider,
   Row,
   Statistic,
   Typography,
   message,
} from 'antd';

import { useNavigate } from 'react-router-dom';
import Layout from 'components/layout/Layout';
import IconBack from 'resources/img/icons/btn-back.png';
import share from 'resources/img/icons/share-icon.png';
import React, { createRef } from 'react';
import { useParams } from 'react-router-dom';
import clock from 'resources/img/icons/clock.png';
import copy from 'resources/img/icons/copy.png';
import outlet from 'resources/img/icons/toko.png';
import downloadIcon from 'resources/img/icons/Download.png';
import infoMisc from 'resources/img/misc/info.png';
import info from 'resources/img/icons/info.png';
import Barcode from 'react-barcode';
import { formatPrice } from 'helpers/formatPrice';
import { parseDate } from 'helpers/Date';
import * as htmlToImage from 'html-to-image';
import { useEffect } from 'react';
import { getDetailTransaction } from 'helpers/GetInitSubProduct';
import { useState } from 'react';
import MenungguBayarTitle from 'helpers/MenungguBayarTitle';
const createFileName = (extension = '', ...names) => {
   if (!extension) {
      return '';
   }

   return `${names.join('')}.${extension}`;
};

const { Countdown } = Statistic;
const { Text } = Typography;
const { Panel } = Collapse;

const MenungguBayar = () => {
   const { id } = useParams();
   const [data, setData] = useState('');
   const navigate = useNavigate();

   const exp = new Date(
      new Date(data?.payment_code?.expired_at).getTime() + 24 * 1000,
   );
   const [messageApi, contextHolder] = message.useMessage();

   const hendleCopyClipboard = (text) => {
      navigator.clipboard.writeText(text);
      messageApi.open({
         type: 'success',
         content: 'Copy to clipboard',
      });
   };
   const ref = createRef(null);
   const takeScreenShot = async (node) => {
      const dataURI = await htmlToImage.toJpeg(node);
      return dataURI;
   };
   const download = (
      image,
      { name = 'screenshot', extension = 'jpg' } = {},
   ) => {
      const a = document.createElement('a');
      a.href = image;
      a.download = createFileName(extension, name);
      a.click();
   };
   const downloadScreenshot = () => takeScreenShot(ref.current).then(download);
   useEffect(() => {
      const detailTransaction = async () => {
         let result = await getDetailTransaction(id);
         setData(result);
      };
      detailTransaction();
   }, []);

   const handleShare = async () => {
      const newFile = await htmlToImage.toBlob(ref.current);
      const data = {
         files: [
            new File([newFile], 'image.png', {
               type: newFile.type,
            }),
         ],
         title: 'Image',
         text: 'image',
      };

      try {
         if (!navigator.canShare(data)) {
            console.error("Can't share");
         }
         await navigator.share(data);
      } catch (err) {
         console.error(err);
      }
   };
   return (
      <Layout header="none" headerTitle="Menunggu Pembayaran">
         {contextHolder}
         <header
            className="ant-layout-header"
            style={{ display: 'flex', left: 0 }}
         >
            <Row
               className="ant-row-center ant-row-middle"
               style={{ maxWidth: '500px', width: '100%' }}
            >
               <Col span={24}>
                  <Row className="ant-row-center ant-row-middle">
                     <Col span={4} className="col-left">
                        <img
                           src={IconBack}
                           alt="Icon Back"
                           className="btn-back"
                           onClick={() => navigate(-1)}
                        />
                     </Col>
                     <Col span={16} className=" header-title col-center">
                        <h4
                           className="ant-typography"
                           style={{ margin: '0px' }}
                        >
                           Menunggu Pembayaran
                        </h4>
                     </Col>
                     <Col span={4} className="col-right">
                        <img
                           src={share}
                           alt="Icon Back"
                           className="btn-share"
                           onClick={handleShare}
                        />
                     </Col>
                  </Row>
               </Col>
            </Row>
         </header>
         <Row className="container-index Top-UP">
            <Row className="row-notification" style={{ marginBottom: 20 }}>
               <Col span={24}>
                  <div className="notif yellow">
                     <img
                        src={infoMisc}
                        alt="payment time"
                        className="info-misc"
                     />
                     <div className="notif-UL">
                        Tunjukan barcode atau kode pembayaran dibawah ini kepada
                        Outlet terdekat untuk menyelesaikan pembayaran.
                     </div>
                  </div>
               </Col>
            </Row>
            <Row className="row-payment" ref={ref}>
               <Col span={12} className="payment">
                  <Text>{data?.product?.product_category?.name}</Text>
               </Col>
               <Col span={12} className="payment col-right">
                  <Text>{MenungguBayarTitle(data)}</Text>
               </Col>
               <Col span={24} className="payment">
                  <Text>{data?.code}</Text>
               </Col>
               <Col span={24} className="payment">
                  <Row>
                     <Col span={18} className="col-left">
                        <Text>Batas Pembayaran:</Text>
                     </Col>
                     <Col span={6} className="col-right">
                        <div className="payment-timer">
                           <img src={clock} alt="payment time" width={12} />
                           <Countdown
                              value={parseDate(exp)}
                              valueStyle={{
                                 fontSize: 12,
                                 margin: '0 3px',
                              }}
                           />
                        </div>
                     </Col>
                  </Row>
               </Col>
               <Col span={24} style={{ padding: '0 8px' }}>
                  <Divider className="divide-menunggu-bayar" />
               </Col>
               <Col span={24} className="payment">
                  <Row>
                     <Col span={18}>
                        <Text>Metode Pembayaran:</Text>
                     </Col>
                     <Col span={6} className="col-right">
                        <img
                           src={outlet}
                           alt="payment outlet"
                           width={18}
                           height={18}
                           style={{ marginRight: 10 }}
                        />
                        <Text>
                           <strong>Outlet</strong>
                        </Text>
                     </Col>
                  </Row>
               </Col>{' '}
               <Col span={24} style={{ padding: '0 8px' }}>
                  <Divider className="divide-menunggu-bayar" />
               </Col>
               <Col span={24} className="payment">
                  <Row>
                     <Col
                        span={24}
                        className="col-center barcode"
                        style={{ height: 100 }}
                     >
                        {data?.payment_code?.code && (
                           <Barcode
                              value={data?.payment_code?.code}
                              width={6}
                           />
                        )}
                     </Col>
                     <Col
                        span={24}
                        className="col-center share-copy"
                        style={{ gap: 10, marginTop: 10 }}
                     >
                        <Button
                           className="button-border"
                           onClick={() =>
                              hendleCopyClipboard(data?.payment_code?.code)
                           }
                        >
                           SALIN{' '}
                           <img
                              src={copy}
                              alt="payment outlet"
                              width={18}
                              height={18}
                              style={{ marginLeft: 10 }}
                           />
                        </Button>
                        <Button
                           className="button-primary"
                           onClick={() => {
                              downloadScreenshot();
                              // showDrawer();
                           }}
                        >
                           SIMPAN SCREENSHOOT
                           <img
                              src={downloadIcon}
                              alt="payment outlet"
                              width={18}
                              height={18}
                              style={{ marginLeft: 10 }}
                           />
                        </Button>
                     </Col>
                  </Row>
               </Col>{' '}
               <Col span={24} style={{ padding: '0 8px' }}>
                  <Divider className="divide-menunggu-bayar" />
               </Col>
               <Col span={24} className="payment">
                  <Row>
                     <Col span={16}>
                        <Text>Total Pembayaran</Text>
                     </Col>
                     <Col
                        span={8}
                        className="col-right"
                        style={{ padding: '0 15px' }}
                     >
                        <Text className="price">
                           <strong>{formatPrice(data?.total)}</strong>
                        </Text>
                     </Col>
                     <Col span={24} style={{ marginTop: 15 }}>
                        <img
                           src={info}
                           alt="payment outlet"
                           width={18}
                           height={18}
                           style={{ marginRight: 10 }}
                        />
                        <Text>Total bayar sudah termasuk biaya layanan</Text>
                     </Col>
                  </Row>
               </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
               <Col span={24}>
                  <Collapse size="small" expandIconPosition="end">
                     <Panel header="Cara Pembayaran" key="1">
                        <ul
                           style={{
                              marginLeft: '-15px',
                              listStyleType: 'number',
                           }}
                        >
                           <li>
                              Datang ke <strong>Outlet</strong>.
                           </li>
                           <li>
                              Tunjukan <strong>kode pembayaran </strong>atau{' '}
                              <strong>barcode</strong>.
                           </li>
                           <li>
                              Bayar dengan uang tunai sesuai total pembayaran
                              (termasuk biaya layanan).
                           </li>
                           <li>
                              Transaksi selesai, simpan bukti pembayaranmu.
                           </li>
                           <li>
                              <strong> Cek status pembayaran</strong> di email
                              kamu (jika kamu isi alamat email kamu di step
                              sebelumnya).
                           </li>
                        </ul>
                        <Divider style={{ margin: '15px 0' }} />
                        <Row>
                           <Col span={24}>
                              <img
                                 src={infoMisc}
                                 alt="payment time"
                                 className="info-misc"
                              />
                              <div className="notif yellow">
                                 <div className="notif-UL">
                                    Kamu bisa bayar di seluruh outlet Wispay
                                    yang terdaftar.
                                 </div>
                              </div>
                           </Col>
                        </Row>
                     </Panel>
                  </Collapse>
               </Col>
            </Row>
         </Row>
      </Layout>
   );
};

export default MenungguBayar;
