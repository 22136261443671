import { isObject, isString } from 'formik';
import { isArray } from 'lodash-es';

export function showErrorMessage(error) {
   if (isString(error?.message)) {
      return error?.message;
   }
   if (isString(error?.data?.message)) {
      return error?.data?.message;
   }
   if (isArray(error?.message?.base)) {
      return error?.message?.base.join(', ');
   }
   if (isArray(error?.message)) {
      return error?.message.join(', ');
   }
   if (isObject(error?.message)) {
      const getArrayKeyName = Object.keys(error.message);
      const getKeyName = getArrayKeyName[0];
      const errorFirstItem = error.message[getKeyName];
      if (isArray(errorFirstItem)) {
         return getKeyName + ' ' + errorFirstItem.join(' ');
      }
      if (isString(errorFirstItem)) {
         return getKeyName + ' ' + errorFirstItem;
      } else {
         return getKeyName;
      }
   }

   return '';
}
const handleError = (error) => {
   let dataErr = {};
   let setMessage = '';
   let setStep = '';
   const { response, message } = error;
   const status = response ? response.status : null;
   try {
      setMessage = response?.data?.data?.message || message;
      setStep = response?.data?.data?.step || '';
      // if (status === 401) {
      //    window.location.reload();
      // }
   } catch (e) {}
   switch (status) {
      case 400:
         dataErr = {
            code: response.status,
            message: setMessage,
            desc: 'Bad Request',
         };
         break;
      case 402:
         dataErr = {
            code: response.status,
            message: setMessage,
            desc: 'Payment Required',
         };
         break;
      case 403:
         dataErr = {
            code: response.status,
            message: setMessage,
            desc: 'Forbidden',
         };
         break;
      case 404:
         dataErr = {
            code: response.status,
            message: setMessage,
            desc: 'Not Found',
         };
         break;
      case 409:
         dataErr = {
            code: response.status,
            message: setMessage,
            desc: 'Conflict',
         };
         break;
      case 422:
         dataErr = {
            code: response.status,
            message: setMessage,
            desc: response.data.response.message,
            step: setStep,
         };
         break;
      case 500:
         dataErr = {
            code: response.status,
            message: setMessage,
            desc: 'Internal Server Error',
         };
         break;
      default:
         dataErr = {
            code: 404,
            message: setMessage,
            desc: message,
         };
   }
   return dataErr;
};

export default handleError;
