import CryptoJS from 'crypto-js';

const ec = (data) => {
   var key = CryptoJS.enc.Utf8.parse('89B4FF7EFAE3EF1811DF9A1CB18774D5');
   var iv = CryptoJS.enc.Utf8.parse('1614556800000000');

   var encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
   });

   return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
};

const dc = (data) => {
   var key = CryptoJS.enc.Utf8.parse('89B4FF7EFAE3EF1811DF9A1CB18774D5');
   var iv = CryptoJS.enc.Utf8.parse('1614556800000000');

   var decrypted = CryptoJS.AES.decrypt(data, key, {
      iv: iv,
   });

   return decrypted.toString(CryptoJS.enc.Utf8);
};

export { ec, dc };
