/* eslint-disable react-hooks/exhaustive-deps */
import {
   Button,
   Col,
   Divider,
   Drawer,
   Row,
   Spin,
   Input,
   Typography,
} from 'antd';
import Layout from 'components/layout/Layout';
import { ec } from 'helpers/Crypto';
import { formatPrice } from 'helpers/formatPrice';
import { phoneNumber } from 'helpers/Number';
import info from 'resources/img/icons/info.png';
import IconBack from 'resources/img/icons/btn-back.png';
import Comingsoon from 'resources/img/upcoming_feature.png';
import {
   getDataCheckout,
   getProductSubCatProduct,
   getTokenProduct,
} from 'helpers/GetInitSubProduct';

import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

const { Title, Text } = Typography;
const PaketData = () => {
   const [open, setOpen] = useState(false);
   const [loading, setLoading] = useState(false);
   const [dataDrawer, setDataDrawer] = useState('');
   const [products, setProducts] = useState([]);
   const [commingSoon, setCommingSoon] = useState(false);
   const [page, setPage] = useState(0);
   const [email, setEmail] = useState('');
   const showDrawer = (data) => {
      setDataDrawer(data);
      setOpen(true);
   };
   const onClose = () => {
      setOpen(false);
   };
   const { slug } = useParams();
   const initproduct = 'data-plan';
   const location = useLocation();
   const id = location.state?.id;
   const idPulsa = location.state?.idPulsa;
   const idPaket = location.state?.idPaket;
   const idBrandPulsa = location.state?.idBrandPulsa;
   const idBrandPaket = location.state?.idBrandPaket;
   const noTelepon = location.state?.noTelepon;
   const getInitData = useCallback(
      async (loadMore = false) => {
         setLoading(true);
         !loadMore ? setPage(1) : setPage(page + 1);

         let params = [];
         params.push(`_limit=10`);
         params.push(`is_digipos=false`);
         params.push(`page=${loadMore ? page + 1 : page || 1}`);

         let product = await getProductSubCatProduct(
            id,
            `&${params.join('&')}`,
         );

         setProducts(product);
         setLoading(false);
         if (product.length === 0) {
            setCommingSoon(true);
         } else {
            setCommingSoon(false);
         }
      },
      [page, products, id],
   );
   useEffect(() => {
      getInitData();
   }, []);
   const handleEmail = (evt) => {
      if (evt.target.value.length > 0) {
         setEmail(evt.target.value);
      }
   };
   const handleLanjutBayar = async () => {
      setLoading(true);

      const body = {
         product_id: dataDrawer?.id, // Get `product_id` references from product hierarchy
         phone: ec(phoneNumber(noTelepon)), // Should be encryption
      };
      try {
         let productSubcategoryId = await getTokenProduct(initproduct, body);
         let chekout = await getDataCheckout(
            initproduct,
            productSubcategoryId?.meta?.token,
            email ? email : null,
         );
         History.push(`/menunggu-bayar/${chekout?.id}`);
         setLoading(false);
      } catch (error) {
         setLoading(false);
      }
   };

   return (
      <>
         {loading && (
            <div>
               <Spin size="large" className="spin-area"></Spin>
               <div className="loading-spin"></div>
            </div>
         )}
         <Layout header="none" headerTitle={slug}>
            <header
               className="ant-layout-header"
               style={{ display: 'flex', left: 0 }}
            >
               <Row
                  className="ant-row-center ant-row-middle"
                  style={{ maxWidth: '500px', width: '100%' }}
               >
                  <Col span={24}>
                     <Row className="ant-row-center ant-row-middle">
                        <Col span={4} className="col-left">
                           <Link
                              to="/pulsa-paket-data"
                              state={{
                                 noTelepon: noTelepon,
                                 id: idPulsa,
                                 IdPaketData: idPaket,
                                 idBrandPulsa: idBrandPulsa,
                                 idBrandPaket: idBrandPaket,
                              }}
                           >
                              <img
                                 src={IconBack}
                                 alt="Icon Back"
                                 className="btn-back"
                                 style={{ marginTop: '-10px' }}
                              />
                           </Link>
                        </Col>
                        <Col span={16} className=" header-title col-center">
                           <h4
                              className="ant-typography"
                              style={{ margin: '0px' }}
                           >
                              {slug}
                           </h4>
                        </Col>{' '}
                        <Col span={4} className="col-left"></Col>
                     </Row>
                  </Col>
               </Row>
            </header>
            <Row className="container-index Top-UP">
               {products?.map((item, index) => (
                  <Row
                     key={index}
                     style={{ marginBottom: '15px', cursor: 'pointer' }}
                     onClick={
                        item?.is_purchasable ? () => showDrawer(item) : null
                     }
                     className={
                        item?.is_purchasable
                           ? 'detail-paket-data enable'
                           : 'detail-paket-data disable'
                     }
                  >
                     <Col span={24}>
                        <Text strong>{item?.name}</Text>
                        <br />
                        <Text>{item?.description} </Text>
                     </Col>
                     <Col
                        span={12}
                        className="price"
                        style={{
                           color: '#cc0a78',
                        }}
                     >
                        {formatPrice(item?.product_price?.total)}
                     </Col>{' '}
                     <Col
                        span={12}
                        className="col-right"
                        style={{ color: 'red' }}
                     >
                        {!item?.is_purchasable ? (
                           <Text className="dalam-kendala">Dalam Kendala</Text>
                        ) : null}
                     </Col>
                  </Row>
               ))}
               {commingSoon ? (
                  <Row>
                     <Col
                        span={24}
                        className="cooming-soon"
                        style={{ marginBottom: 30 }}
                     >
                        <img
                           src={Comingsoon}
                           alt="indosat"
                           className="comingsoon-img"
                        />
                     </Col>
                     <Col
                        span={24}
                        style={{ textAlign: 'center', padding: '0 40px' }}
                     >
                        <Title level={5}>Layanan ini akan segera hadir</Title>
                        <Text>
                           Mohon bersabar menunggu, Layanan ini akan segera
                           hadir di Wispay dalam waktu dekat.
                        </Text>
                     </Col>
                  </Row>
               ) : null}
               <Drawer
                  title="Paket Data"
                  placement="bottom"
                  closable={true}
                  onClose={onClose}
                  open={open}
                  key="bottom"
                  height={420}
               >
                  <Row>
                     <Col span={12}>
                        <p>Nama Produk</p>
                     </Col>
                     <Col span={12} className="drawer-col-right">
                        {dataDrawer?.name}
                     </Col>
                     <Col span={12}>
                        <p>Nomor Hp</p>
                     </Col>
                     <Col span={12} className="drawer-col-right">
                        {noTelepon}
                     </Col>
                     <Col span={12}>
                        <p>Harga</p>
                     </Col>
                     <Col span={12} className="drawer-col-right">
                        {formatPrice(dataDrawer?.product_price?.total)}
                     </Col>
                     <Col span={24} className="email-input">
                        <Text>Alamat Email (optional)</Text>
                        <Input
                           placeholder="Masukan Alamat Email"
                           id="validating"
                           className="input-topUP"
                           onChange={handleEmail}
                        />
                     </Col>
                     <Col span={24}>
                        <div className="notif yellow">
                           <img
                              src={info}
                              alt="payment time"
                              className="info-misc"
                           />
                           <div className="notif-UL">
                              Jika kamu ingin mendapatkan bukti pembayaran atas
                              pembelian ini, mohon isi alamat email diatas.
                           </div>
                        </div>
                     </Col>
                  </Row>
                  <Divider />
                  <Row>
                     <Col span={12}>
                        <Text>Total Bayar</Text>
                        <br />
                        <Text strong className="price-paket">
                           {formatPrice(dataDrawer?.total)}
                        </Text>
                     </Col>
                     <Col span={12} className="drawer-col-right">
                        <Button
                           className="lanjut-bayar"
                           onClick={() => handleLanjutBayar()}
                        >
                           Lanjut Bayar
                        </Button>
                     </Col>
                  </Row>
               </Drawer>
            </Row>
         </Layout>
      </>
   );
};

export default PaketData;
