import Beranda from 'resources/img/icons/header-beranda.png';
import BerandaActive from 'resources/img/icons/header-beranda-active.png';

const HeaderMenu = [
   {
      title: 'Beranda',
      normal: Beranda,
      active: BerandaActive,
      link: '/',
   },
];

export { HeaderMenu };
