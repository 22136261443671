import React from 'react';
import { Layout, Col, Row, Image, Typography } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import logoWhite from 'resources/img/wispay-logo.png';
import IconBack from 'resources/img/icons/btn-back.png';
import { HeaderMenu as HeaderData } from 'resources/data/HeaderMenu';
import HeaderMenu from './HeaderMenu';
const { Title } = Typography;

const Header = (props) => {
   const { type, title } = props;
   const navigate = useNavigate();
   const location = useLocation();

   return (
      <Layout.Header
         className={type === 'index' && 'header-bottom'}
         style={
            type === 'index'
               ? { maxWidth: 500 }
               : type === 'none'
               ? { display: 'none' }
               : { display: 'flex' }
         }
      >
         <Row
            justify="center"
            align="middle"
            style={{ maxWidth: 500, width: '100%' }}
         >
            <Col span={24}>
               <Row
                  justify="center"
                  align="middle"
                  className={type === 'index' && 'header-icon-section'}
               >
                  {type === 'product' ? (
                     <>
                        <img
                           src={IconBack}
                           alt="Icon Back"
                           className="btn-back"
                           onClick={() => navigate(-1)}
                        />
                        <Col className="header-title">
                           <Title level={4} style={{ margin: 0 }}>
                              {title}
                           </Title>
                        </Col>
                     </>
                  ) : type === 'index' ? (
                     <>
                        {HeaderData.map((item, index) => (
                           <Link key={index} to={`${item.link}`}>
                              <HeaderMenu
                                 image={
                                    location.pathname === item.link
                                       ? item.active
                                       : item.normal
                                 }
                                 title={item.title}
                                 active={location.pathname === item.link}
                              />
                           </Link>
                        ))}
                     </>
                  ) : type === 'pulsa' ? (
                     <>
                        <Link to="/">
                           <img
                              src={IconBack}
                              alt="Icon Back"
                              className="btn-back"
                           />
                           <Col className="header-title">
                              <Title level={4} style={{ margin: 0 }}>
                                 {title}
                              </Title>
                           </Col>
                        </Link>
                     </>
                  ) : (
                     <Col span={24} className="header-outlet">
                        <Row>
                           <Col span={24}>
                              <Link to="/">
                                 <Image
                                    src={logoWhite}
                                    alt="logo wispay"
                                    preview={false}
                                    className="logo"
                                 />
                              </Link>
                           </Col>
                        </Row>
                     </Col>
                  )}
               </Row>
            </Col>
         </Row>
      </Layout.Header>
   );
};

export default Header;
