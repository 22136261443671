/* eslint-disable react-hooks/exhaustive-deps */
import {
   Button,
   Col,
   Divider,
   Drawer,
   Form,
   Input,
   Row,
   Spin,
   Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Layout from 'components/layout/Layout';
import { formatPrice } from 'helpers/formatPrice';
import Masonry from 'react-smart-masonry';
import InfiniteScroll from 'react-infinite-scroll-component';
import info from 'resources/img/icons/info.png';
import {
   getTokenProduct,
   getProductSubCatagories,
   getProductSubCatProduct,
   getDataCheckout,
} from 'helpers/GetInitSubProduct';
import { ec } from 'helpers/Crypto';
import { phoneNumber } from 'helpers/Number';
import History from 'helpers/History';
const { Text } = Typography;
const DompetEL = () => {
   const { slug } = useParams();
   const [isLastPage, setIsLastPage] = useState(false);
   const [loading, setLoading] = useState(false);
   const [isDisable, setIsDisable] = useState(true);
   const [page, setPage] = useState(0);
   const [products, setProducts] = useState([]);
   const [open, setOpen] = useState(false);
   const [noHP, setNoHP] = useState(false);
   const [email, setEmail] = useState('');
   const [dataDrawer, setDataDrawer] = useState('');
   const location = useLocation();
   const id = location.state?.id;
   const initproduct = 'ewallet';

   const getInitData = useCallback(
      async (loadMore = false) => {
         !loadMore ? setPage(1) : setPage(page + 1);
         let productSubcategoryId = await getProductSubCatagories(id);
         let params = [];
         params.push(`_limit=14`);
         params.push(`page=${loadMore ? page + 1 : page || 1}`);
         let product = await getProductSubCatProduct(
            productSubcategoryId?.[0]?.id,
            `&${params.join('&')}`,
         );
         setProducts([...products, ...product]);
         if (loadMore && product?.length < 14) {
            setIsLastPage(true);
            return true;
         }
      },
      [isLastPage, page, products, slug],
   );
   useEffect(() => {
      getInitData(true);
   }, []);
   const loadMoreData = useCallback(async () => {
      if (isLastPage) {
         return;
      } else {
         setPage(page + 1);
         setTimeout(() => {
            getInitData(true);
         }, 1000);
      }
   }, [getInitData, isLastPage, page]);
   const showDrawer = (data) => {
      setDataDrawer(data);
      setOpen(true);
      setLoading(false);
   };
   const onClose = () => {
      setDataDrawer('');
      setOpen(false);
   };
   const handleNoHPChange = (evt) => {
      if (evt.target.value.length < 13 && evt.target.value.length > 0) {
         setIsDisable(false);
         setNoHP(evt.target.value);
      } else {
         setIsDisable(true);
      }
   };
   const handleEmail = (evt) => {
      if (evt.target.value.length > 0) {
         setEmail(evt.target.value);
      }
   };
   const handleLanjutBayar = async (id, phone) => {
      setLoading(true);
      const body = {
         product_id: id, // Get `product_id` references from product hierarchy
         phone: ec(phoneNumber(phone)), // Should be encryption
      };
      try {
         let productSubcategoryId = await getTokenProduct(initproduct, body);
         let chekout = await getDataCheckout(
            initproduct,
            productSubcategoryId?.meta?.token,
            email ? email : null,
         );
         History.push(`/menunggu-bayar/${chekout?.id}`);
         setLoading(false);
      } catch (error) {
         setLoading(false);
      }
   };
   return (
      <>
         {loading && (
            <div>
               <Spin size="large" className="spin-area"></Spin>
               <div className="loading-spin"></div>
            </div>
         )}
         <Layout header="product" headerTitle={slug}>
            <Row className="container-index Top-UP">
               <Form name="basic">
                  <Form.Item hasFeedback>
                     <Input
                        type="number"
                        placeholder="Masukan Nomor HP"
                        id="validating"
                        className="input-topUP"
                        onChange={handleNoHPChange}
                        allowClear
                     />
                  </Form.Item>
               </Form>
               <Row className="row-icon-DompetEL" style={{ marginTop: 10 }}>
                  <InfiniteScroll
                     dataLength={products.length}
                     next={loadMoreData}
                     hasMore={!isLastPage}
                     loader={
                        <div
                           className="center"
                           style={{
                              marginTop: 20,
                              textAlign: 'center',
                              maxWidth: '400px',
                           }}
                        >
                           <Spin />
                        </div>
                     }
                     className="section-infinite-scroll produk-serupa"
                     style={{ overflow: 'visible' }}
                  >
                     <Masonry columns={2} gap={10}>
                        {products?.map((item, index) => (
                           <Col
                              span={24}
                              key={index}
                              onClick={
                                 !isDisable
                                    ? item?.is_purchasable
                                       ? () => showDrawer(item)
                                       : null
                                    : null
                              }
                              className={
                                 !isDisable
                                    ? item?.is_purchasable
                                       ? 'enable'
                                       : 'disable'
                                    : 'disable'
                              }
                           >
                              <div className="DompetEL-text">
                                 <Text>
                                    <strong>{item.name}</strong>
                                 </Text>
                                 <Text className="price">
                                    {formatPrice(item?.product_price?.total)}
                                 </Text>
                                 {!isDisable ? (
                                    !item?.is_purchasable ? (
                                       <Text className="dalam-kendala">
                                          Dalam Kendala
                                       </Text>
                                    ) : null
                                 ) : null}
                              </div>
                           </Col>
                        ))}
                     </Masonry>
                  </InfiniteScroll>
               </Row>
            </Row>
            <Row className="container-index Top-UP">
               <Drawer
                  title="Dompet Elektronik"
                  placement="bottom"
                  closable={true}
                  onClose={onClose}
                  open={open}
                  key="bottom"
                  height={470}
               >
                  <Row className="drawer-row border-top">
                     <Col span={12}>
                        <Text>Nama Produk</Text>
                     </Col>
                     <Col span={12} className="drawer-col-right">
                        {dataDrawer?.name}
                     </Col>
                     <Col span={12}>
                        <Text>Nomor Hp</Text>
                     </Col>
                     <Col span={12} className="drawer-col-right">
                        {noHP}
                     </Col>
                     <Col span={12}>
                        <Text>Harga</Text>
                     </Col>
                     <Col span={12} className="drawer-col-right">
                        {formatPrice(dataDrawer?.product_price?.price)}
                     </Col>
                     <Col span={12}>
                        <Text>Biaya Admin</Text>
                     </Col>
                     <Col span={12} className="drawer-col-right">
                        {formatPrice(dataDrawer?.product_price?.admin_fee)}
                     </Col>
                     <Col span={24} className="email-input">
                        <Text>Alamat Email (optional)</Text>
                        <Input
                           placeholder="Masukan Alamat Email"
                           id="validating"
                           className="input-topUP"
                           onChange={handleEmail}
                        />
                     </Col>
                     <Col span={24}>
                        <div className="notif yellow">
                           <img
                              src={info}
                              alt="payment time"
                              className="info-misc"
                           />
                           <div className="notif-UL">
                              Jika kamu ingin mendapatkan bukti pembayaran atas
                              pembelian ini, mohon isi alamat email diatas.
                           </div>
                        </div>
                     </Col>
                  </Row>
                  <Divider />
                  <Row className="drawer-row">
                     <Col span={12}>
                        <Text>Total Bayar</Text>
                        <br />
                        <Text strong className="price-paket">
                           {formatPrice(dataDrawer?.product_price?.total)}
                        </Text>
                     </Col>
                     <Col span={12} className="drawer-col-right">
                        <Button
                           className="lanjut-bayar"
                           onClick={() =>
                              handleLanjutBayar(dataDrawer.id, noHP)
                           }
                        >
                           Lanjut Bayar
                        </Button>
                     </Col>
                  </Row>
               </Drawer>
            </Row>
         </Layout>
      </>
   );
};

export default DompetEL;
